.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.overlayCloseButton {
  position: absolute;
  top: -15px; /* Adjust top position */
  right: -15px; /* Adjust right position */
  background: #ffffff;
  color: #9d63b0;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional for better visibility */
  border: none;
}
.videoListWrapper {
  display: flex;
  width: 100%;
  min-width: 1400px;
  height: 550px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  align-items: stretch;
}

.videoHolder {
  flex: 2;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.fluid-width-video-wrapper {
  width: 100%;
  max-width: 900px; /* Adjust max width for responsiveness */
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
}

.fluid-width-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoListScroll {
  flex: 1;
  overflow-y: auto;
  background: #f5f5f5;
}

.videoWrapper {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #e3e3e3;
  transition: background 0.3s ease;
}

.videoWrapper:hover {
  background: #f0f0f0;
}

.videoWrapper.selected {
  background: #000000;
  color: #ffffff;
  pointer-events: none;
}

.videoWrapper.selected .videoTitle {
  color: #ffffff;
}

.videoWrapper .left {
  width: 100px;
  height: 55px;
  background: #000000;
  margin-right: 15px;
  position: relative;
  overflow: hidden;
}

.videoWrapper .left img {
  width: 100%;
  height: auto;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.videoWrapper .right {
  flex: 1;
  line-height: 1.5;
}

.videoWrapper .videoTitle {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.videoWrapper .videoInfo {
  font-size: 12px;
  opacity: 0.6;
}

@media (max-width: 1400px) {
  .videoListWrapper {
    min-width: unset;
    width: 100%;
    flex-direction: column;
    height: calc(100vh - 100px);
  }
}

@media (max-width: 960px) {
  .videoListWrapper {
    flex-direction: column;
    height: auto;
  }
  .fluid-width-video-wrapper {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
  }
  .videoWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .videoWrapper .left {
    width: 80px;
    height: 45px;
  }
  .videoWrapper .videoTitle {
    font-size: 12px;
  }
  .videoWrapper .videoInfo {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .videoListWrapper {
    height: auto;
    padding: 10px;
    max-height: 80vh;
    overflow-y: auto;
  }
  .videoWrapper {
    flex-direction: row;
    align-items: center;
  }
  .videoWrapper .left {
    width: 70px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .videoWrapper {
    flex-direction: row;
    align-items: center;
    padding: 10px;
  }
  .videoWrapper .left {
    width: 60px;
    height: 35px;
  }
  .videoWrapper .videoTitle {
    font-size: 10px;
  }
  .videoWrapper .videoInfo {
    font-size: 9px;
  }
}
